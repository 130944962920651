import Util from './../plugins/util';
import lodash from 'lodash';
import TouchEvents from './../plugins/touchevents';

/**
 * --------------------------------------------------------------------------
 * Daikin ProductFinder (v1.0.4): productfinder.js
 *
 * Changelog:
 * 1.0.4 - DAI001-1579
 *
 * --------------------------------------------------------------------------
 */

const ProductFinder = (($) => {

    /**
     * ------------------------------------------------------------------------
     * Constants
     * ------------------------------------------------------------------------
     */

    const NAME = 'productFinder';
    const VERSION = '1.0.3';
    const DATA_KEY = 'daikin.productFinder';
    const EVENT_KEY = `.${DATA_KEY}`;
    const DATA_API_KEY = '.data-api';
    const JQUERY_NO_CONFLICT = $.fn[NAME];

    const MAX_ITEMS_PER_ROW = 4;

    const Default = {

    }

    const Event = {
        LOAD_DATA_API: `load${EVENT_KEY}${DATA_API_KEY}`,
        CLICK_DATA_API : `click${EVENT_KEY}${DATA_API_KEY}`
    }

    const ClassName = {

    }

    const Selector = {
        DATA_TOGGLE : '[data-toggle="productfinder"]',
        DATA_CATEGORY: '[data-category]',
        DATA_PRODUCT: '[data-product]',
        DATA_REGISTER_LABEL: 'register-label',
        DATA_COMMISSIONING_CODE: 'commissioning-code',
        DATA_HELP_TITLE: 'help-title',
        DATA_HELP_DESCRIPTION: 'help-description',
        GRID_ROW: '.grid-row',
        GRID: '.grid-wrapper',
        GRID_SCROLLER: '.grid',
        GRID_CONTROLS: '.grid-controls',
        GRID_PAGING: '.grid-paging',
        GRID_PAGING_ITEM: '.grid-paging-item',
        BACK: '.product-selector-back'
    }

    const Type = {
        CATEGORY: 'category',
        PRODUCT: 'product',
        PRODUCT_LIST: 'productlist'
    }

    /**
     * ------------------------------------------------------------------------
     * Class Definition
     * ------------------------------------------------------------------------
     */

    class ProductFinder {

        constructor(element, config) {
            this._config    = config;
            this._data      = null;
            this._element   = element;

            this._categories = null;
            this._products = null;
            this._previousLevel = null;
            this._breadcrumb = [];

            this._colWidth = false;
            this._scrollIndex = 0;
            this._scrollPageIndex = 0,
            this._numItems = 0;

            this._productGridVisible = false;

            this._parseData();
            this._renderCategories();
            this._addEventListeners();
        }

        // Getters
        static get VERSION() {
            return VERSION;
        }

        // Public
        render(id, type) {

            this._resetGrid();
            this._productGridVisible = false;

            if(type === Type.CATEGORY) {
                this._renderCategories(id);
            }
            else if(type === Type.PRODUCT_LIST)
            {
                this._renderCategories(id, true);
            }
            else
            {
                this._renderProduct(id);
            }

            this._renderBackButton();
        }

        _clickCategory(el, event) {

            let category = _.clone($(el).data('category'), true);

            // Add current selected category to breadcrumb (DAI001-421)
            this._breadcrumb.push(category);

            this.render(category.id, Type.CATEGORY);

            event.preventDefault();
        }

        _clickProduct(el, event) {

            let product = $(el).data('product');
            let category = _.clone($(el).data('category'), true);

            category.isProductList = true;

            // Add current selected category to breadcrumb (DAI001-421)
            this._breadcrumb.push(category);

            this.render(product.id, Type.PRODUCT);

            event.preventDefault();
        }

        _back(el, event) {
            // Get last level from breadcrumb and remove it
            let targetLevel = this._breadcrumb.pop();

            if(!targetLevel.isProductList) {
                this.render(targetLevel.parent_id, Type.CATEGORY);
            }
            else
            {
                this.render(targetLevel.id, Type.PRODUCT_LIST);
            }

            event.preventDefault();
        }

        // Private
        _addEventListeners() {
            let self = this;
            let element = $(self._element);
            let target = element.find(Selector.GRID_ROW);
            let resizeTimer;

            $(document)
                .on(Event.CLICK_DATA_API, Selector.DATA_CATEGORY, function(event) {
                    self._clickCategory(this, event);
                })
                .on(Event.CLICK_DATA_API, Selector.DATA_PRODUCT, function(event) {
                    self._clickProduct(this, event);
                })
                .on('click', Selector.BACK, function(event) {
                    self._back(this, event);
                });

            $(window)
                .on('resize', function() {
                    clearTimeout(resizeTimer);
                    resizeTimer = setTimeout(function() {
                        // Run code here, resizing has "stopped"
                        if(self._productGridVisible && element.width() >= 700) {
                            self._calculateColumnWidths();
                            self._setColumnWidths(target);
                            self._setGridOffset();
                        }
                        else {
                            self._resetGrid();
                        }

                    }, 150);
                });



            self._addScrollListeners();
        }

        _addScrollListeners() {
            let self = this,
                controls = $(this._element).find(Selector.GRID_CONTROLS);

            controls.find('.grid-control').on('click', function(e) {
                e.preventDefault();
                let direction = $(this).data('direction');

                self._moveGrid(direction, controls);
            });

            $(this._element).find(Selector.GRID_SCROLLER)
                .on('swiperight', function(e) {
                    self._moveGrid('left', controls);
                })
                .on('swipeleft', function(e) {
                    self._moveGrid('right', controls);
                });

        }

        _parseData() {
            this._categories = $.parseJSON($(this._element).find('#productfinder-categories').html());
            this._products = $.parseJSON($(this._element).find('#productfinder-productRanges').html());
        }

        _getCategoryById(id) {
            let result;

            if(id) {
                $.each(this._categories, function (index, value) {
                    if (value.id === id) {
                        result = value;
                        return false;
                    }
                })
            }

            return result;
        }

        _renderBackButton(id) {
            var self = this;
            let item = this._breadcrumb[this._breadcrumb.length-1];

            if(item) {
                $.each(this._categories, function(index, value) {
                    if(value.id === item.id) {

                        $(self._element).find(Selector.BACK)
                            .show()
                            .data('previous', value.parent_id)
                            .find('span')
                            .text(value.title);
                    }
                })
            }
            else
            {
                // Hide backbutton
                $(self._element).find(Selector.BACK).hide();
            }

        }

        _renderCategories(parentId = null, isProductList = false) {
            let sorted = new Array();
            let target = $(this._element).find(Selector.GRID_ROW);

            $.each(this._categories, function(index, value) {
                if(value.parent_id === parentId) {

                    sorted.push(value);
                }
            })

            // Clear HTML
            target.html('');

            if(!isProductList) {
                // Categories found -> render categories
                if(sorted.length > 1) {
                    this._renderCategory(lodash.sortBy(sorted, 'position'), target);
                }

                // If only one category was found
                else if(sorted.length == 1)
                {
                    // Skip to level deeper (DAI001-421)
                    this._renderCategories(sorted[0].id);
                }

                // No categories, find products
                else
                {
                    this._renderProducts(parentId, target);
                }
            }
            else
            {
                this._renderProducts(parentId, target);
            }
        }

        _renderCategory(data, target) {
            let template, category, image;

            $.each(data, function(index, value) {

                if(value.image) {
                    image = value.image;
                }
                else
                {
                    image = value.default_image;
                }

                template = `<article data-category="" class="grid-col product-selection">
                                <div class="product-border">
                                    <a class="thumbnail text-center" href="">
                                        <img alt="" src="${image}" class="">
                                        <div class="caption"></div>
                                    </a>
                                </div>
                            </article>`

                // Prepare the template
                category = $(template);

                // Safely set title
                category.find('.caption').text(value.title);

                // Assign category data
                category.data('category', value);

                target.append(category);
            })
        }

        _renderProducts(categoryId, target) {

            let template;
            let product;
            let image;
            let self = this;

            let products = _.map(_.filter(this._products, function(product) { return product.category_ids.indexOf(categoryId) > -1 }), 'id');

            let numProducts = products.length,
                colWidth = null,
                scroller;

            self._resetGrid();
            self._productGridVisible = false;

            // Check if category has more than 1 product (DAI001-358)
            if(numProducts > 1) {
                self._productGridVisible = true;

                if(numProducts > MAX_ITEMS_PER_ROW && $(self._element).width() >= 700) {

                    self._numItems = numProducts;

                    self._calculateColumnWidths();

                } else {
                    self._colWidth = false;
                }

                this._renderProductColumns(products, categoryId, target);
                this._renderPaging(numProducts);
            }
            else
            {
                // Render the single product detail
                this._renderProduct(products[0]);
            }
        }

        _renderPaging(numProducts) {
            let page;
            const self = this;
            const paging = $(self._element).find(Selector.GRID_PAGING);
            const numPages = Math.ceil(numProducts / MAX_ITEMS_PER_ROW);

            // Reset paging
            paging.empty();

            for (let i = 0; i < numPages; i++) {
                let pageItem = $(self._renderPage(i + 1));

                pageItem.on('click', function(event) {
                    self._setPage(i);
                });

                paging.append(pageItem);
            }
        }

        _renderPage(page) {
            return `
                <button class="grid-paging-item${page === 1 ? ' active' : ''}">
                    <span class="sr-only">${page}</span>
                </button>
            `;
        }

        _setActivePage(currentPage) {
            const pageItems = $(this._element).find(Selector.GRID_PAGING_ITEM);

            pageItems.removeClass('active');
            pageItems.eq(currentPage).addClass('active');

            this._setActiveControls();
        }

        // Show the correct page when clicking paging button
        _setPage(page) {
            const self = this;
            const numItems = self._numItems;

            let index = page * MAX_ITEMS_PER_ROW;
            let remainingScrollableItems = numItems - index;

            if (remainingScrollableItems < MAX_ITEMS_PER_ROW) {
                index -= remainingScrollableItems;
            }

            if (page === 0) {
                index = 0;
            }

            self._scrollIndex = index;
            self._scrollPageIndex = page;

            self._setActivePage(page);
            self._setGridOffset();
        }

        _resetGrid() {
            let self = this,
                controls = $(Selector.GRID_CONTROLS),
                paging = $(self._element).find(Selector.GRID_PAGING);

            $(self._element).find(Selector.GRID)
                .removeClass('is-scrollable')
                .css('width', 'auto');

            self._colWidth = false;
            //self._productGridVisible = false;
            self._scrollIndex = 0;
            self._scrollPageIndex = 0;

            $(this._element).find(Selector.GRID_SCROLLER).css('left', 0);

            // Hide left initially
            controls.find('.control-left').toggleClass('active', false);
            controls.find('.control-right').toggleClass('active', true);

            // Reset paging
            paging.empty();

            self._setColumnWidths($(self._element).find(Selector.GRID_ROW));
        }

        _calculateColumnWidths() {
            let availableWidth = $(this._element).width();

            this._colWidth = Math.round(availableWidth / MAX_ITEMS_PER_ROW);

            $(this._element).find(Selector.GRID)
                .addClass('is-scrollable')
                .width(this._colWidth * MAX_ITEMS_PER_ROW);
        }

        _setColumnWidths(target) {
            let self = this;
            let column = target.find('.grid-col');

            if(self._colWidth) {
                column.width(self._colWidth);
            }
            else {
                column.css('width', 'auto');
            }
        }

        _moveGrid(direction, controls) {
            let self = this;


            let index = self._scrollIndex,
                numItems = self._numItems,
                pageIndex = self._scrollPageIndex;

            if(direction == 'left') {
                if (index >= MAX_ITEMS_PER_ROW) {
                    index -= MAX_ITEMS_PER_ROW;
                    pageIndex--;
                } else {
                    index = 0;
                    pageIndex--;
                }
            }

            if(direction == 'right') {
                const remainingScrollableItems = numItems - ((pageIndex + 1) * MAX_ITEMS_PER_ROW);

                if (remainingScrollableItems >= MAX_ITEMS_PER_ROW) {
                    pageIndex++;
                    index += MAX_ITEMS_PER_ROW;
                } else {
                    pageIndex++;
                    index += remainingScrollableItems;
                }
            }

            self._scrollIndex = index;
            self._scrollPageIndex = pageIndex;

            self._setActivePage(pageIndex);
            self._setGridOffset();
        }

        _setActiveControls() {
            let controls = $(Selector.GRID_CONTROLS),
                controlLeft = controls.find('.control-left'),
                controlRight = controls.find('.control-right'),
                pageIndex = this._scrollPageIndex;

            const numPages = Math.ceil(this._numItems / MAX_ITEMS_PER_ROW);

            controlLeft.toggleClass('active', true);
            controlRight.toggleClass('active', true);

            if (pageIndex === 0) {
                controlLeft.toggleClass('active', false);
            } else if (pageIndex === numPages - 1) {
                controlRight.toggleClass('active', false);
            }
        }

        _setGridOffset() {
            let self = this;

            $(this._element).find(Selector.GRID_SCROLLER).css('left', -(self._scrollIndex * self._colWidth));
        }

        _renderProductColumns(products, categoryId, target) {
            let image,
                product,
                template,
                self = this;

            $.each(this._products, function (index, value) {

                if (value.category_ids.indexOf(categoryId) > -1) {

                    if (value.image) {
                        image = value.image;
                    }
                    else {
                        image = value.default_image;
                    }

                    let category = self._getCategoryById(categoryId);

                    template = `<article data-product="" class="grid-col product-selection">
                                            <div class="product-border">
                                                <a class="thumbnail text-center" href="">
                                                    <img alt="" src="${image}" class="">
                                                    <div class="caption"></div>
                                                </a>
                                            </div>
                                        </article>`;

                    // Prepare the template
                    product = $(template);

                    // Safely set name
                    product.find('.caption').text(value.name);

                    // Assign category data
                    product
                    .data({
                        'product'  : value,
                        'category' : category
                    });

                    if(self._colWidth) {
                        product.width(self._colWidth);
                    }
                    else {
                        product.css('width', 'auto');
                    }

                    target.append(product);

                }
            })
        }

        _renderProduct(productId) {
            let image, template, product, url, action;
            let element = $(this._element);
            let target = element.find(Selector.GRID_ROW);
            let self = this;
            let label = element.data(Selector.DATA_REGISTER_LABEL);
            let helpTitle = element.data(Selector.DATA_HELP_TITLE);
            let helpDescription = element.data(Selector.DATA_HELP_DESCRIPTION);

            // Clear HTML
            target.html('');

            $.each(this._products, function(index, value) {
                // Get current product
                if(value.id === productId) {

                    if(value.image) {
                        image = value.image;
                    }
                    else
                    {
                        image = value.default_image;
                    }

                    // If product is browsable, use product register url
                    // If product requires commissioning code, then render code form
                    if(!Boolean(self._config.isbrowsable) || value.requires_commissioning_code == 1) {
                        url = self._config.registerurl;
                        action = `
                                <form method="post" action="${url}">
                                    <div class="row">
                                        <div class="form-group col-md-6">
                                            <label>${element.data(Selector.DATA_COMMISSIONING_CODE)}</label>
                                            <a href="" class="icon icon-help" data-toggle="popover" title="" data-content="${helpDescription}" data-placement="right" data-original-title="${helpTitle}"></a>
                                            <input type="text" class="form-control" name="customer_commissioning_registration[code]" />
                                        </div>
                                    </div>

                                    <p><input type="submit" class="btn btn-primary btn-sm" value="${label}" /></p>
                                </form>`;
                    }
                    // Else render the normal register this product link
                    // Use regular registration url
                    else
                    {
                        url = self._config.baseurl.replace('SLUG', value.slug);
                        // Normal product, render register button
                        action = `<p><a href="${url}" class="btn btn-primary btn-sm">${label}</a></p>`;
                    }

                    template = `<article class="grid-col product-selection productfinder-detail">
                                    <div class="product-border">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <span class="thumbnail text-center">
                                                    <img alt="" src="${image}" class="" />
                                                </span>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="content">
                                                    <h1 class="h3"></h1>
                                                    <p class="js-description"></p>
                                                    ${action}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>`;

                    // Prepare the template
                    product = $(template);

                    // Safely set the name and description
                    product.find('h1').text(value.name);
                    product.find('.js-description').text(value.description);

                    // Assign category data
                    product.data('product', value);

                    // Enable popovers in template content
                    product.find('a[data-toggle="popover"]')
                        .on('click', function(e) {
                            e.preventDefault()
                        })
                        .popover({
                            'trigger': 'click'
                        });

                    target.append(product);
                }
            })
        }

        // Static
        static _jQueryInterface(config) {
            return this.each(function () {
                let $this = $(this);
                let data  = $this.data(DATA_KEY);
                let _config = $.extend(
                    {},
                    ProductFinder.Default,
                    $(this).data(),
                    typeof config === 'object' && config
                );

                if (!data) {
                    data = new ProductFinder(this, _config);
                    $this.data(DATA_KEY, data);
                }

                if (typeof config === 'string') {
                    data[config]();
                }
            })
        }
    }

    /**
     * ------------------------------------------------------------------------
     * Data Api implementation
     * ------------------------------------------------------------------------
     */

    $(window)
        .on(Event.LOAD_DATA_API, () => {
            $(Selector.DATA_TOGGLE).each(function() {
                let $productFinder = $(this);
                ProductFinder._jQueryInterface.call($productFinder, $productFinder.data());
            })
        });

    /**
     * ------------------------------------------------------------------------
     * jQuery
     * ------------------------------------------------------------------------
     */

    $.fn[NAME]             = ProductFinder._jQueryInterface;
    $.fn[NAME].Constructor = ProductFinder;
    $.fn[NAME].noConflict  = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT;
        return ProductFinder._jQueryInterface;
    }

    return ProductFinder;

})(jQuery)

export default ProductFinder;
