import Dropdown from './plugins/dropdown'
import Tab from './plugins/tab'
import Collapse from './plugins/collapse'
import Modal from './plugins/modal'
import Alert from './plugins/alert'
import Popover from './plugins/popover'
import Mask from 'jquery-mask-plugin/dist/jquery.mask'
import Tether from 'tether/dist/js/tether'
import Datepicker from 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min'

// import CookieConsent from './plugins/cookieconsent'

// Modules
import ProductFinder from './modules/productfinder';
import EquipmentVerification from './modules/equipmentVerification';

/**
 * ------------------------------------------------------------------------
 * Link listeners
 * ------------------------------------------------------------------------
 */

// DAI001-534: open tabs by clicking on header
$('div[data-toggle="trigger-tab"]').on('click', function(e)
{
    let self = $(this),
        link = $(self.data('target')),
        eventTarget = $(e.target);

    if(!eventTarget.hasClass('btn') && !eventTarget.hasClass('btn-link')) {
        if(self.hasClass('active')) {
            link.removeClass('active')
            self.removeClass('active')
        }
        else
        {
            $(self.data('target')).trigger('click');
            
            // If clicked on collection tab, euqlizeElementHeights
            if(self.data('type') === 'collection') {
                // Timeout for making sure calculation if being performed AFTER tab is open (otherwise height is 0);
                setTimeout(() => equalizeElementHeightsByGroup(self.attr('id')), 150);
            }
        }
    }

})

$('a[data-toggle="trigger-link"]').on('click', function(e)
{
    e.preventDefault()

    // Trigger the link containing the href attribute of the link clicked
    $(this.getAttribute('href')).trigger('click')
})

$('a[data-toggle="trigger-closetab"]').on('click', function(e)
{
    e.preventDefault()

    // Trigger the link containing the href attribute of the link clicked
    $(this.getAttribute('href')).removeClass('active')
    $(this).closest('.tab-pane').removeClass('active')
})

let paymentOptions = $('input[data-toggle="payment"]')

if(paymentOptions.length) {
    paymentOptions.on('change', function (e) {
        let self = $(this),
            parent = self.parent()

        // Reset classes
        paymentOptions.parent().removeClass('active')

        // Set active class for current selected item
        parent.toggleClass('active', self.is(':checked'))
    })

    paymentOptions.each(function (i) {
        if ($(this).is(':checked')) {
            $(this).trigger('change')
        }
    })
}

$('a[data-toggle="popover"]')
    .on('click', function(e) {
        e.preventDefault()
    })
    .popover({
        'trigger': 'click'
    })

$('a.js-print-btn').on('click', function(e) {
    e.preventDefault()
    window.print()
})

// Print modal dialogs (DAI001-340)
$('.modal')
    .on('shown.bs.modal', function() {
        // Get modal
        let body = $('body')

        body.addClass('print-dialog')
    })

    .on('hidden.bs.modal', function() {
        // Get modal
        let body = $('body')

        body.removeClass('print-dialog')
    })

/**
 * ------------------------------------------------------------------------
 * Service partner change (DAI001-543)
 * ------------------------------------------------------------------------
 */

changePartner()
function changePartner()
{
    let partnerChange = $('section.js-partner-change')

    if(partnerChange.length)
    {
        let value = partnerChange.data('value');
        let alertMsg = partnerChange.find('.js-partner-alert');
        let noResultMsg = partnerChange.find('.js-partner-no-results');

        partnerChange.find('input[type="radio"]').on('change', function(e) {
            noResultMsg.hide();
            if($(this).val() != value && !$(this).data('is-affiliate')) {
                alertMsg.show();
            }
            else
            {
                alertMsg.hide();
            }
        })
    }
}


/**
 * ------------------------------------------------------------------------
 * Service partner search (DAI001-1663)
 * ------------------------------------------------------------------------
 */
let partnerChange = $('section.js-partner-change')
if(partnerChange.length)
{
	let alertMsg = partnerChange.find('.js-partner-alert');
    let partnerSearchInput = partnerChange.find('.js-partner-search-input');
    let partnerSearchBtn = partnerChange.find('.js-partner-search-btn');
    let partnerSearchContainer = partnerChange.find('.js-partner-search-container');
    let lookupUrl = partnerSearchBtn.data('url');
    let searching;

    // Addded listener for keydown, preventing enter key from submitting the form
	partnerSearchInput.on('keydown', function(e) {
	    if(e.keyCode == 13) {
	        e.preventDefault();

	        // Trigger partnerSearchBtn, which triggers AJAX call
            partnerSearchBtn.trigger('click');

	        return false;
        }
    });

    partnerSearchBtn.on('click', function(e) {

        // Check if selected item was one which was AJAX loaded
	    let selectedCount = partnerSearchContainer.find('input[type="radio"]:checked').length;

        searching = $.ajax({
            type: "POST",
            url: lookupUrl,
            data: {'q': partnerSearchInput.val() },
            success: function(data) {
	            if(selectedCount > 0) {
		            // Hide the alertMsg
		            alertMsg.hide();
	            }

	            partnerSearchContainer.empty();
                partnerSearchContainer.html(data);
                partnerSearchContainer.show();
                changePartner();
            },
        })
    })
}

/**
 * ------------------------------------------------------------------------
 * Google map integration (DAI001-94)
 * ------------------------------------------------------------------------
 */

let map,
    marker

// Modal listeners
$('div.modal-map')
    .on('show.bs.modal', function(e) {
        let modal = $(this),
            button = $(e.relatedTarget)

        modal
            .find('.modal-title')
            .html(button.data('title'))

        modal.find('.modal-googlemaps').hide();

    })
    .on('shown.bs.modal', function(e) {

        let modal = $(this),
            button = $(e.relatedTarget),
            coordinates = {
                lat: button.data('map-lat'),
                lng: button.data('map-long')
            }

        initGoogleMap(coordinates, button.data('info'))

        modal.find('.modal-googlemaps').show()

        //google.maps.event.trigger(map, "resize");
    })


var infoWindow = null

// DRAW GOOGLE MAP
function initGoogleMap(coordinates, data) {
    let latLng = new google.maps.LatLng(coordinates.lat, coordinates.lng);
    let contentString = '<div id="content">' +
                        '<div id="siteNotice">'+
                        '</div>'+
                        '<h2 id="firstHeading" class="h4">' + data.companyName + '</h2>'+
                        '<div id="bodyContent">'+
                        '<p>' +
                        data.address.street + ' ' + data.address.number + ((data.address.box) ? data.address.box : '') + '<br />' +
                        data.address.postalCode + ' ' + data.address.city + '<br />' +
                        ((data.phoneNumber) ? data.phoneNumber + '<br />' : '') +
                        ((data.website) ? '<a href="' + data.website + '">' + data.website + '</a>' : '') +
                        '</p>' +
                        '</div>'+
                        '</div>';


    if(infoWindow) {
        // Close possibly open window
        infoWindow.close();
    }

    infoWindow = new google.maps.InfoWindow()
    infoWindow.setContent(contentString);


    if(map) {
        map.setCenter(latLng)
        marker.setPosition(latLng)

        // Clear event
        google.maps.event.clearListeners(marker, 'click');
    }
    else
    {
        map = new google.maps.Map(document.getElementById('modal-googlemaps'), {
            center: latLng,
            zoom: 14
        })

        marker = new google.maps.Marker({
            position: coordinates,
            map: map,
            title: data.title
        })
    }

    marker.addListener('click', function() {
        infoWindow.open(map, marker)
    })

}

/**
 * ------------------------------------------------------------------------
 * Detection for Internet Exploder
 * ------------------------------------------------------------------------
 */

let html = $(html)

function detectIE() {
    let ua = window.navigator.userAgent
    let msie = ua.indexOf('MSIE ')

    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }

    let trident = ua.indexOf('Trident/')

    if (trident > 0) {
        // IE 11 => return version number
        let rv = ua.indexOf('rv:')
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }

    let edge = ua.indexOf('Edge/')
    if (edge > 0) {
        // IE 12 => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }

    // other browser
    return false
}

/**
 * ------------------------------------------------------------------------
 * Equalize height in hero shot, only in IE
 * ------------------------------------------------------------------------
 */

// DAI001-2300: Disabled the detectIE() method
// Fixes a Chrome bug occuring in v63 & v64. Will be fixed in v65.


// Re-enable detectIE() wrapper method after Chrome v65 is released
// if(detectIE()) {
let heroSection = $('section.hero-section');

if (heroSection.length > 0) {
    // Get columns
    let columns = $('div.js-equalize-height');
    let largestHeight = 0;

    columns.each(function (i) {
        let column = $(this);

        // Get largest column
        let height = column.height();

        largestHeight = (height > largestHeight) ? height : largestHeight;
    });

    columns.height(largestHeight);
}

/**
 * ------------------------------------------------------------------------
 * Form listeners
 * ------------------------------------------------------------------------
 */

// Radiobutton toggle element boxes
$('input[type="radio"].toggle').on('click', function() {
    var showTarget = $($(this).data('show')),
        hideTarget = $($(this).data('hide'));

    showTarget.show();
    hideTarget.hide();
})

$('input[type="radio"].toggle:checked').trigger('click');

// Checkbox to toggle elements
$('input[data-toggle="collapse"]:checked').each(function() {
    var target = $($(this).data('target')),
        inverse = $(this).data('inverse');

    if(!inverse) {
        target.addClass('in');
    }
    else {
        target.removeClass('in');
    }
})

$('input[type="radio"].product-select').on('change', function(e) {
    let self = $(this)
    let form = self.closest('form')

    // Get parent form
    form.find('article.product').removeClass('active')

    self.closest('article.product').addClass('active')

    if (self.val().indexOf('#') !== -1) {
        form.find('a.js-btn-order')
            .attr('href', '#')
            .attr('data-toggle', 'modal')
            .attr('data-target', self.val())
        ;
    } else {
        form.find('a.js-btn-order')
            .attr('href', self.val())
            .attr('data-toggle', '')
            .attr('data-target', '')
    }

    form.find('a.js-btn-order').attr('href', self.val());

})

$('input[type="radio"].product-select:checked').trigger('change')

$('a.js-readmore').on('click', function(e) {
    e.preventDefault()

    let self = $(this),
        parent = self.closest('div'),
        summary = parent.find('.js-summary'),
        fulltext = parent.find('.js-fulltext'),
        toggle = self.data('fulltext') || false

    summary.toggle(toggle)
    fulltext.toggle(!toggle)
    self
        .data('fulltext', !toggle)
        .text(!toggle ? self.data('label-less') : self.data('label-more'))

});

// File upload
$('input[type="file"]').on('change', function() {
    let self = $(this),
        parent = self.closest('.file-upload');

    if(parent.length) {
        parent.find('input[type="text"]').val(self.val().replace('C:\\fakepath\\', ''));
    }
})

// Add unit
$('a.js-unit-add').on('click', function(e) {
    e.preventDefault();

    addUnit($(this).data('type'));
})

function addUnit(type) {
    let container = $('div.js-unit-container[data-type="' + type + '"]')
    let template = $(container.data('template'))
    let fieldset = template.find('fieldset').clone()
    let maxUnits = container.data('max-units')

    // Get the current number of units in the container
    let count = container.find('fieldset').length + 1

    // Update the count value
    fieldset
        .find('.js-unit-number')
        .text(count)
        .css('display', 'inline-block')

    fieldset.addClass('unit js-unit js-unit-row');

    // Add it to the placeholder
    container.append(fieldset)

    if(count == maxUnits) {
        $('a.js-unit-add').closest('fieldset').hide()
    }

    updateUnits(type)

    return fieldset;
}

function updateUnits(type) {
    let container = $('div.js-unit-container[data-type="' + type + '"]'),
        fieldset = container.find('fieldset.js-unit')

    // Get the current number of units in the container
    let count = fieldset.length

    if(count == 1) {
        fieldset.find('.js-unit-number').hide()
    }
    else {
        fieldset.find('.js-unit-number').eq(0).css('display', 'inline-block')

        for(let i = 2; i <= count; i++) {
            fieldset.eq(i-1)
                .find('.js-unit-number')
                .css('display', 'inline-block')
                .text(i)
        }
    }
}

$(document).on('click', 'a.js-unit-remove', function(e) {
    e.preventDefault();

    let container = $(this).closest('div.js-unit-container')
    let type = container.data('type');
    let maxUnits = container.data('max-units')

    $(this).closest('fieldset').remove()

    if(container.find('fieldset').length - 1 < maxUnits) {
        $('a.js-unit-add[data-type="' + type + '"]').closest('fieldset').show()
    }

    updateUnits(type)
})

$('form.js-register')
    .on('submit', function(e) {
        let units = []
        let container = $('.js-units-container')

        if(container.length) {
            container.find('fieldset.js-unit').each(function(i) {
                let fieldset = $(this)

                units.push({
                    type: fieldset.data('type'),
                    material: fieldset.find('.js-unit-material').val(),
                    serial: fieldset.find('.js-unit-serial').val(),
                    numberOfPanels: fieldset.find('.js-unit-panels').val()
                })
            })
        }

        container.find('.js-units').val(JSON.stringify(units))
    })

    .find('.js-units').each(function(i) {
        let self = $(this),
            values = $.parseJSON(self.val()),
            multiples = {}

        if(values) {
            $.each(values, function(index, value) {
                // Get the corresponding fieldset

                let fieldset

                fieldset = $('fieldset.js-unit[data-type="' + value.type + '"]')

                if (value.type == 'indoor' || value.type == 'gateway' || value.type == 'outdoor') {
                    if (typeof multiples[value.type] == 'undefined') {
                        multiples[value.type] = true;
                    } else {
                        fieldset = addUnit(value.type);
                    }
                }

                fieldset.find('.js-unit-material').val(value.material);
                fieldset.find('.js-unit-serial').val(value.serial);
                fieldset.find('.js-unit-panels').val(value.numberOfPanels);
            })
        }
    })

$('input[type="radio"][data-toggle="option"]').on('click', function() {
    let self = $(this),
        parent = self.closest('.js-option'),
        name = self.attr('name'),
        siblings = $('input[name="' + name + '"]');

    siblings
        .closest('.js-option')
        .removeClass('active')

    parent.addClass('active')
})

$('input.js-form-other').on('focus', function(e) {
    let self = $(this),
        parent = self.parent(),
        checkbox = parent.find('input[type="checkbox"]')

    if(checkbox.length > 0) {
        checkbox.prop('checked', true)
    }
})

// Auto complete
$.each($('input.js-autocomplete-lookup'), function() {
    let lookupInput = $(this),
        lookupUrl = lookupInput.data('url'),
        valueInput = $(lookupInput.data('target'))

    lookupInput.attr('autocomplete', 'off')

    initAutoComplete(lookupInput, valueInput, lookupUrl)
});

function initAutoComplete(lookupInput, valueInput, lookupUrl)
{
    let searching

    lookupInput.typeahead({
        items: 5,
        minLength: 3,
        source: function(query, process) {

            // Stop search call if is busy
            if (typeof searching != "undefined") {
                searching.abort()
                searching = undefined
                process([])
            }

            // start new search query
            searching = $.getJSON(
                lookupUrl,
                {
                    q: query
                },
                function (data) {
                    return process(data)
                }
            );
        },
        afterSelect: function(item) {
            if (!item) {
                valueInput.val('')
            } else {
                valueInput.val(item.id)
            }
            lookupInput
                .data('selected', item.name)
                .blur()
        }
    })

    lookupInput.on('change', function() {
        let input = $(this),
            selectedValue = input.data('selected'),
            newValue = input.val()

        if (selectedValue && selectedValue != newValue) {
            valueInput.val('')
        }
    })
}

/**
 * ------------------------------------------------------------------------
 * Equipment verification
 * ------------------------------------------------------------------------
 */

let unitsContainer = $('.js-units-container');

if(unitsContainer.length && unitsContainer.data('verification-enabled')) {
    let evUnits = new EquipmentVerification(unitsContainer, unitsContainer.data('url'));
}

/**
 * ------------------------------------------------------------------------
 * Hide alternative professionals by default
 * https://marlon.atlassian.net/browse/DAI001-4120
 * ------------------------------------------------------------------------
 */
$("#btn-show-more-service-partner").on('click', function (e) {
    e.preventDefault();
    this.remove();
    $(".partner.d-none").removeClass("d-none");
});

/**
 * ------------------------------------------------------------------------
 * Epackage collection alignment
 * ------------------------------------------------------------------------
 */

function equalizeElementHeightsByGroup(id)
{
    // Fetch DOM-nodes
    const root = (typeof id === 'string') ? document.getElementById(id) : document;
    const elements = Array.from(root.querySelectorAll('[data-height-group]'));
    // No need to equalize heights if there's only 1 element
    if (elements.length < 2) return;
    // Calculate all max element-heights per group
    const groupHeights = elements.reduce((groups, element) => {
        const group = element.getAttribute('data-height-group');
        const height = element.offsetHeight;
        return {
            ...groups,
            [group]: Math.max(groups[group] || 0, height),
        };
    }, {});
    // Set each elements' height to their max group-height
    elements.forEach((element) => {
        const groupHeight = groupHeights[element.getAttribute('data-height-group')];
        if (groupHeight > 0) {
            element.style.boxSizing = 'border-box';
            element.style.height = groupHeight + 'px';
        }
    });
}

window.addEventListener('load', equalizeElementHeightsByGroup);
window.addEventListener('resize', equalizeElementHeightsByGroup);


/**
 * ------------------------------------------------------------------------
 * Show project specific packages
 * https://jira.hosted-tools.com/browse/DAI001-6286
 * ------------------------------------------------------------------------
 */
 $("#btn-show-more-project-specific-packages").on('click', function (e) {
    e.preventDefault();
    this.remove();
    $(".project-specific-package").removeClass("d-none");
});
