export default function EquipmentVerification(unitsContainer, evUrl) {
    let _xhr;
    
    function verify(material, serial, country, unitRow) {
        if(!serial || !material) {
            return;
        }
        
        _xhr = $.ajax({
            method: "POST",
            url: evUrl,
            data: {
                'material': material,
                'serial': serial,
                'country': country,
            },
            success: function(data) {
                renderFeedback(unitRow, data.Message);
            }
        })
    }
    
    function renderFeedback(unitRow, message) {
        let isError = false;
        
        if(message) {
            isError = true;
        }
        
        let feedback = unitRow.find('.js-feedback');
        let successIcon = unitRow.find('.js-feedback-icon');
    
    
        if(isError) {
            unitRow.toggleClass('has-error', isError);
            unitRow.toggleClass('has-success', !isError);
            feedback.html('<i class="icon icon-danger icon-lg"></i> ' + message);
            feedback.show();
        }
        else {
            unitRow.toggleClass('has-error', isError);
            unitRow.toggleClass('has-success', !isError);
            feedback.hide();
        }
    
        successIcon.toggle(!isError);
    }
    
    function handleChange(e) {
        const target = $(e.currentTarget);
        
        let unitRow = target.closest('.js-unit-row'),
            material,
            serial,
            country = $('.js-country-select');

        if (!country.length) {
            country = $('.js-installation-country-select');
        }
        
        switch(e.data.type) {
            case 'serial':
                material = unitRow.find('.js-unit-material');
                serial = target;
                break;
            case 'material':
                serial = unitRow.find('.js-unit-serial');
                material = target;
                break;
        }
        
        verify(material.val(), serial.val(), country.val(), unitRow);
    }
    
    function addListeners() {
        $(document).on('blur', '.js-unit-serial', { type: 'serial'}, handleChange);
        $(document).on('change', '.js-unit-material', { type: 'material'}, handleChange);
        
        $('.js-unit-serial').trigger('blur');
        $('.js-unit-material').trigger('change');
    }
    
    addListeners();
}